.news_title {
    text-align: center;
    color: #01b3b0;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 50px;
}

.news_content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 50px;
    margin-bottom: 50px;
    padding: 0px 100px;
}

.news_card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px 0px 0px 0px;
    border-radius: 20px;
}

.swiper-slide>img {
    width: 100%;
    height: 100%;
}

.news_card_text {
    font-size: 16px;
    font-style: italic;
    color: dodgerblue;
    padding: 20px 50px;
}

.video1 {
    width: 100%;
    padding: 0px 50px;
}

.news_card_video {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px 0px 0px 0px;
    border-radius: 20px;
}