:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.leader {
    margin: 50px 0px;
}

.leader_top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.leader_title {
    color: var(--text-color);
    font-size: 35px;
    font-weight: 700;
    line-height: 48px;
}

.leader_top_line {
    width: 120px;
    height: 6px;
    background-color: var(--text-color);
    border-radius: 3px;
}

.leader_content {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    grid-template-rows: 250px 250px;
    justify-content: space-between;
    gap: 50px;
}

.leder_card_images {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    transition: .5s;
}

.leader_card {
    border-radius: 20px;
    overflow: hidden;
    transition: .5s;
    position: relative;
}

.leader_card:hover .leder_card_images {
    transform: scale(1.1);
    transition: .5s;
}

.leder_card_hover {
    width: 100%;
    height: 100%;
    background-color: #0000006f;
    position: absolute;
    top: -100%;
    left: 0px;
    transition: .5s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.leader_card:hover .leder_card_hover {
    top: 0px;
    transition: .5s;
}

.leder_card_text {
    color: white;
    font-size: 18px;
}

.leder_card_link {
    padding: 2px 15px;
    background-color: white;
    text-decoration: none;
    color: var(--text-color);
    cursor: pointer;
    transition: .5s;
    border-radius: 5px;
    border: 1px solid transparent;
}

.leder_card_link:hover {
    color: white;
    background-color: transparent;
    border-color: white;
    transition: .5s;
}

@media only screen and (max-width:1400px) {
    .leader_content {
        gap: 20px;
        row-gap: 50px;
    }
}

@media only screen and (max-width:1300px) {
    .leader_content {
        grid-template-columns: 250px 250px 250px 250px;
        grid-template-rows: 200px 200px;
        gap: 50px;
    }

    .leder_card_text {
        font-size: 16px;
    }
}

@media only screen and (max-width:1200px) {
    .leader_content {
        grid-template-columns: 250px 250px 250px;
        grid-template-rows: 200px 200px 200px;
        gap: 50px;
    }
}

@media only screen and (max-width:1024px) {
    .leader_title {
        font-size: 24px;
        line-height: 44px;
    }

    .leader_top {
        gap: 0px;
    }

    .leader_content {
        grid-template-columns: 250px 250px 250px;
        grid-template-rows: 200px 200px 200px;
        gap: 25px;
    }
}

@media only screen and (max-width:850px) {
    .leader_title {
        font-size: 24px;
        line-height: 44px;
    }

    .leader_top {
        gap: 0px;
    }

    .leader_content {
        grid-template-columns: 250px 250px;
        grid-template-rows: 200px 200px 200px 200px;
        gap: 50px;
        justify-content: center;
    }
}

@media only screen and (max-width:600px) {

    .leader_content {
        grid-template-columns: 220px 220px;
        grid-template-rows: 180px 180px 180px 180px;
        gap: 20px;
    }

    .leder_card_text {
        font-size: 14px;
    }

    .leder_card_link {
        padding: 2px 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width:550px) {

    .leader_title {
        font-size: 20px;
    }

    .leader_top_line {
        width: 100px;
        height: 4px;
    }
}

@media only screen and (max-width:500px) {

    .leader_content {
        grid-template-columns: 250px;
        grid-template-rows: 200px 200px 200px 200px 200px 200px 200px 200px;
        gap: 50px;
    }

    .leder_card_link {
        font-size: 12px;
    }
}