:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.sections {
    margin: 50px 0px;
}

.sections_title {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    line-height: 48px;
    color: var(--text-color);
    margin-bottom: 50px;
}

.sections_content {
    display: grid;
    grid-template-columns: 250px 250px 250px 250px;
    grid-template-rows: 280px 280px 280px;
    justify-content: space-between;
    gap: 50px;
}

.sections_card {
    background: linear-gradient(117deg, #01b3b0, #0497d2);
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    position: relative;
    overflow-y: hidden;
}

.sections_card_images {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
}

.sections_card_text {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    color: white;
}

.section_card_hover {
    width: 100%;
    height: 100%;
    background-color: #0000006f;
    position: absolute;
    top: -100%;
    left: 0px;
    transition: .5s;
}

.sections_card:hover .section_card_hover {
    top: 0;
    transition: .5s;
}

.sections_card_link {
    width: 100px;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-decoration: none;
    color: var(--text-color);
    border: 1px solid transparent;
}

.sections_card_link:hover {
    color: white;
    transition: .5s;
    background-color: transparent;
    border-color: white;
}


@media only screen and (max-width:1200px) {
    .sections_content {
        grid-template-columns: 250px 250px 250px;
        grid-template-rows: 280px 280px 280px 280px;
        row-gap: 50px;
    }
}

@media only screen and (max-width:900px) {
    .sections_content {
        grid-template-columns: 220px 220px 220px;
        grid-template-rows: 250px 250px 250px 250px;
        gap: 0px;
        row-gap: 50px;
    }
}

@media only screen and (max-width:768px) {
    .sections {
        margin: 30px 0px;
    }

    .sections_title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }

    .sections_content {
        display: grid;
        grid-template-columns: 220px 220px 220px;
        grid-template-rows: 250px 250px 250px 250px;
        justify-content: space-between;
        gap: 0px;
        row-gap: 30px;
    }

    .sections_card {
        padding: 10px;
        border-radius: 10px;
    }

    .sections_card_text {
        font-size: 18px;
    }

    .sections_card_link {
        font-size: 14px;
    }

}

@media only screen and (max-width:740px) {
    .sections_content {
        grid-template-columns: 220px 220px;
        grid-template-rows: 250px 250px 250px 250px 250px 250px;
        justify-content: center;
        gap: 30px;
    }

}

@media only screen and (max-width:600px) {
    .sections_title {
        font-size: 28px;
    }
}

@media only screen and (max-width:500px) {
    .sections_title {
        font-size: 24px;
    }

    .sections_content {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto auto auto;
        justify-content: center;
        gap: 30px;
    }

    .sections_card_text {
        font-size: 16px;
    }

    .sections_card_link {
        font-size: 12px;
    }

}


@media only screen and (max-width:400px) {
    .sections_title {
        font-size: 18px;
    }

    .sections_content {
        grid-template-columns: 250px;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto;
        gap: 25px;
    }

}