.medic_title {
    text-align: center;
    margin-bottom: 30px;
    color: #01b3b0;
    font-size: 35px;
}

.medic_content {
    padding: 50px 250px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 350px 350px;
    gap: 50px;
}

.medic_card {
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
    position: relative;
}

.medic_images {
    width: 100%;
    height: 100%;
    transition: .5s;
}

.medic_card:hover .medic_images {
    transform: scale(1.1);
    transition: .5s;
}

.medic_card_content {
    width: 100%;
    height: 100%;
    background-color: #0000006f;
    position: absolute;
    top: -100%;
    left: 0px;
    transition: .5s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.medic_card:hover .medic_card_content {
    top: 0px;
    transition: .5s;
}


@media only screen and (max-width:1350px) {
    .medic_content {
        padding: 50px 200px;
    }
}

@media only screen and (max-width:1250px) {
    .medic_content {
        padding: 50px 200px;
        grid-template-rows: 300px 300px;
        gap: 30px;
    }
}

@media only screen and (max-width:1150px) {
    .medic_content {
        padding: 50px 150px;
    }
}

@media only screen and (max-width:1050px) {
    .medic_content {
        padding: 50px 100px;
    }
}

@media only screen and (max-width:950px) {
    .medic_content {
        padding: 50px 100px;
        grid-template-rows: 250px 250px;
        gap: 30px;
    }
}

@media only screen and (max-width:850px) {
    .medic_content {
        padding: 30px 50px;
        grid-template-rows: 250px 250px;
        gap: 25px;
    }

    .medic_title {
        font-size: 26px;
    }
}

@media only screen and (max-width:750px) {
    .medic_content {
        padding: 30px 0px;
        grid-template-rows: 280px 280px;
        gap: 25px;
    }

    .medic_title {
        font-size: 26px;
    }
}

@media only screen and (max-width:650px) {
    .medic_content {
        padding: 30px 0px;
        grid-template-columns: 300px;
        align-content: center;
        justify-content: center;
        grid-template-rows: 250px 250px 250px 250px;
        gap: 30px;
    }

    .medic_title {
        font-size: 26px;
    }
}


@media only screen and (max-width:375px) {
    .medic_content {
        padding: 0px 30px 30px 30px;
        grid-template-columns: 1fr;
        align-content: center;
        justify-content: center;
        grid-template-rows: auto auto auto auto;
        gap: 30px;
    }

    .medic_title {
        font-size: 22px;
    }
}