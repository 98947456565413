:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.work {
    margin: 50px 0px;
}

.work_title {
    text-align: center;
    color: var(--text-color);
    font-size: 35px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 50px;
}

.work_card {
    background-color: hsla(0, 0%, 100%, .6);
    border-radius: 10px;
    box-shadow: 0 6px 60px rgba(0, 0, 0, .15);
    height: 390px;
    margin: 30px auto;
    padding: 30px;
    display: flex;
    justify-content: space-between;
}

.work_card_left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.work_card_title_text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #01b3b0;
    font-size: 22px;
}

.work_card_title {
    color: var(--hover-color);
    font-weight: 700;
}

.work_card_text {
    display: flex;
    flex-direction: column;
    color: var(--hover-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
}

.work_card_work_text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: black;
}

.work_card_phone_text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--hover-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
}

.work_card_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.work_card_images {
    width: 270px;
}

.work_card_data {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--hover-color);
    font-weight: 700;
}

.work_card_icons {
    font-size: 30px;
}

@media only screen and (max-width:800px) {
    .work_title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .work_card_title_text {
        font-size: 18px;
    }

}

@media only screen and (max-width:650px) {
    .work {
        margin: 30px 0px;
    }

    .work_title{
        font-size: 28px;
    }
    
    .work_card {

        height: auto;
        flex-direction: column;
        gap: 50px;
        justify-content: flex-start;
    }
    

}

@media only screen and (max-width:500px) {
    .work {
        margin: 25px 0px;
    }
    
    .work_title {
        font-size: 24px;
        line-height: 30px;
    }
    
    .work_card {
        padding: 25px;
    }
    
    .work_card_left {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    
    .work_card_title_text {
        font-size: 18px;
    }
    
    
    .work_card_images {
        width: 100%;
    }
    
}

@media only screen and (max-width:400px) {
    
    .work_title {
        font-size: 18px;
    }
    
    .work_card {
        padding: 25px;
    }
    
    .work_card_title_text {
        font-size: 16px;
    }

.work_card_text{
    font-size: 14px;
}

.work_card_work_text{
    font-size: 14px;
}
    
    
    .work_card_images {
        width: 50%;
    }
    
}