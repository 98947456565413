* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container {
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #008080;
  border-radius: 20px;
}