:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}


.contact {
    padding: 50px 250px 100px 250px;
    background-color: hsla(0, 0%, 100%, .7);
    border-radius: 30px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
    margin: 50px 0px;
}

.contact_title {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 3px;
    color: var(--text-color);
    margin-bottom: 50px;
}

.contact_content {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.contact_left {
    width: 100%;
}

.contact_left_title {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    color: var(--text-color);
    margin-bottom: 25px;
}

.contact_form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contact_form_input {
    background-color: transparent;
    border: 1px solid var(--text-color);
    border-radius: 5px;
    color: var(--text-color);
    font-size: 16px;
    height: 40px;
    padding-left: 16px;
    outline: none;
    width: 100%;
}

.contact_form_textarea {
    background-color: transparent;
    border: 1px solid #01b3b0;
    border-radius: 10px;
    color: #01b3b0;
    font-size: 16px;
    height: 100px;
    margin-top: 20px;
    padding-left: 16px;
    padding-top: 10px;
    outline: none;
    width: 100% !important;
}

.contact_form_textarea::-webkit-scrollbar {
    width: 7px;
}

.contact_form_textarea::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #008080;
    border-radius: 20px;
}

.contact_form_btn {
    width: 120px;
    height: 30px;
    background-color: var(--text-color);
    color: white;
    cursor: pointer;
    border-radius: 8px;
    transition: .5s;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid transparent;
}

.contact_form_btn:hover {
    color: var(--hover-color);
    background-color: white;
    border-color: var(--hover-color);
    transition: .5s;
}

.contact_right {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
    width: 100%;
}

.contact_link_text {
    font-size: 18px;
    color: var(--text-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact_link_icon {
    font-size: 38px;
    padding: 5px;
    background-color: var(--text-color);
    color: white;
    border-radius: 50%;
}

.contact_link_text:hover .contact_link_icon {
    animation: contact_icons .5s 1 linear;
}

@keyframes contact_icons {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}




@media only screen and (max-width:1350px) {
    .contact {
        padding: 50px 100px 100px 100px;
    }
}

@media only screen and (max-width:1024px) {
    .contact {
        padding: 50px;
    }

    .contact_title {
        font-size: 35px;
        margin-bottom: 30px;
    }

    .contact_right {
        gap: 20px;
    }

    .contact {
        border-radius: 10px;
    }

    .contact_content {
        gap: 50px;
    }
}



@media only screen and (max-width:800px) {
    .contact {
        padding: 30px 70px 50px 70px;
    }

    .contact_title {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .contact_content {
        flex-direction: column;
    }

    .contact_left_title {
        text-align: center;
        font-size: 18px;
    }

    .contact_form {
        align-items: center;
        justify-content: center;
    }

    .contact_right {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 10px;
    }
}




@media only screen and (max-width:768px) {
    .contact {
        padding: 30px 50px;
    }

    .contact_title {
        font-size: 28px;
        margin-bottom: 0px;
    }


    .contact_left_title {
        text-align: center;
        font-size: 16px;
    }

    .contact_form {
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .contact_form_textarea {
        margin-top: 0px;
    }

    .contact_link_text {
        font-size: 16px;
    }

    .contact_link_icon {
        font-size: 28px;
    }

}


@media only screen and (max-width:600px) {
    .contact {
        padding: 25px;
    }

    .contact_title {
        font-size: 24px;
    }

    .contact_content {
        gap: 30px;
    }


    .contact_right {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 15px;
    }
}

@media only screen and (max-width:400px) {
    .container {
        padding: 0px 10px;
    }

    .contact {
        padding: 20px;
        border-radius: 5px;
        margin: 20px 0px;
    }


    .contact_title {
        font-size: 18px;
    }

    .contact_content {
        gap: 25px;
    }


    .contact_right {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
    }
}