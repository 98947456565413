.overlay {
    width: 100vw;
    height: 100vh;
    background-color: #0000006f;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
}

@media only screen and (min-width:769px) {
    .overlay {
        display: none;
    }
}