:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.faqs {
    padding: 50px 300px 0px 300px;
}

.faqs_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 50px;
}

.faqs_title {
    font-size: 35px;
    color: var(--text-color);
}

.faqs_line {
    width: 80px;
    height: 5px;
    background-color: var(--text-color);
    border-radius: 5px;
}

.faqs_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.faqs_card_icons {
    font-size: 25px;
}

.faqs_card {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    padding: 15px;
    border-radius: 20px;
    background-color: #b8edf86f;
    cursor: pointer;
    transition: .5s;
}

.faqs_card:hover {
    background-color: #94cbd66f;
    transition: .5s;
}

.faqs_card_top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.faqs_card_text {
    font-size: 18px;
    font-weight: 500;
}


@media only screen and (max-width:1300px) {
    .faqs {
        padding: 50px 200px 0px 200px;
    }

}

@media only screen and (max-width:1150px) {
    .faqs {
        padding: 50px 100px 0px 100px;
    }

}

@media only screen and (max-width:1024px) {
    .faqs_title {
        font-size: 24px;
    }

}

@media only screen and (max-width:950px) {
    .faqs {
        padding: 50px 0px 0px 0px;
    }

}

@media only screen and (max-width:768px) {
    .faqs_card_text {
        font-size: 16px;
    }

    .faqs_click_text {
        font-size: 14px;
    }

}

@media only screen and (max-width:550px) {
    .faqs_title {
        font-size: 20px;
    }

    .faqs_card_text {
        font-size: 14px;
    }

    .faqs_click_text {
        font-size: 12px;
    }

}