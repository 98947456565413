:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.about_content {
    backdrop-filter: blur(6px);
    background: hsla(0, 0%, 53%, .08);
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, .25), 0 3px 7px -3px rgba(0, 0, 0, .3);
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding: 50px;
    border-radius: 10px;
}

.about_top_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 30px;
}

.about_title {
    text-align: center;
    font-size: 35px;
    color: var(--text-color);
}

.about_line {
    text-align: center;
    background-color: var(--text-color);
    border-radius: 5px;
    height: 6px;
    width: 100px;
}

.about_text {
    font-size: 18px;
    line-height: 25px;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 30px;
}

.about_images {
    width: 50%;
    border-radius: 10px;
}

.about_icons_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.about_icons {
    font-size: 30px;
    color: var(--text-color);
    transition: .5s;

}

.about_icons:hover {
    color: var(--hover-color);
    transition: .5s;
}


@media only screen and (max-width:1100px) {
    .about_content {
        height: 550px;
        display: flex;
        gap: 20px;
        padding: 20px;
    }

    .about_top_text {
        margin-bottom: 20px;
    }

    .about_title {
        font-size: 28px;
    }

    .about_text {
        font-size: 18px;
        line-height: 24px;
    }

    .about_images {
        width: 100%;
        height: 100%;
    }

}

@media only screen and (max-width:1024px) {
    .about_content {
        padding: 25px;
        height: auto;
        flex-direction: column;
    }


    .about_title {
        font-size: 24px;
    }

    .about_text {
        font-size: 16px;
        line-height: 22px;
    }

    .about_images {
        width: 80%;
        height: 400px;
    }

}


@media only screen and (max-width:768px) {
    .about_content {
        padding: 25px;
        height: auto;
    }


    .about_title {
        font-size: 24px;
    }

    .about_text {
        font-size: 14px;
        line-height: 20px;
    }

    .about_images {
        width: 100%;
        height: 300px;
    }

}

@media only screen and (max-width:550px) {
    .about_content {
        padding: 20px;
    }


    .about_title {
        font-size: 22px;
    }


    .about_images {
        height: auto;
    }

    .about_line {
        height: 4px;
    }

    .about_icons_group {

        gap: 20px;
    }

    .about_icons {
        font-size: 22px;

    }
}

@media only screen and (max-width:550px) {
    .about_title {
        font-size: 20px;
    }


    .about_icons_group {
        gap: 15px;
    }

    .about .about_text {
        font-size: 12px;
        line-height: 18px;
    }

    .about_icons {
        font-size: 20px;
    }
}