:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
    --footer-color: #fff;
}

.footer_bg {
    background: linear-gradient(117deg, #01b3b0, #0497d2);
}

.footer {
    padding: 50px 0px;
}

.footer_content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fafafa;
    padding-bottom: 20px;
}

.footer_logo {
    margin-bottom: 30px;
}

.footer_location_icons,
.footer_phone_icons {
    color: var(--footer-color);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 6px;
}

.footer_region_text {
    color: var(--footer-color);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}

.footer_phone_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer_phone_number {
    color: var(--footer-color);
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
}

.footer_card_title {
    color: var(--footer-color);
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 30px;
}

.footer_navbar {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer_navbar_list {
    color: var(--footer-color);
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.footer_location_img {
    width: 100%;
    height: 177px;
    background-size: cover;
    padding-bottom: 20px;
}

.footer_web_site_link {
    color: var(--footer-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
}

.footer_follow_text {
    color: var(--footer-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
}

.footer_icon_group {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer_icons_link {
    color: var(--footer-color);
    font-size: 25px;
}


.footer_logo{
    width: 150px;
}


@media only screen and (max-width:1024px) {
    .footer_region_text {
        max-width: 200px;
    }
}

@media only screen and (max-width:850px) {
    .footer_logo {
        margin-bottom: 20px;
    }

    .footer_location_icons,
    .footer_phone_icons {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .footer_region_text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .footer_phone_number {
        font-size: 16px;
    }

    .footer_card_title {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .footer_navbar {
        gap: 10px;
    }

    .footer_navbar_list {
        font-size: 16px;
        line-height: 20px;
    }

    .footer_location_img {
        height: 100px;
    }

    .footer_web_site_link {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .footer_follow_text {
        font-size: 16px;
    }

    .footer_icon_group {
        gap: 15px;
    }

    .footer_icons_link {
        font-size: 22px;
    }

}

@media only screen and (max-width:768px){
    .footer_logo{
        width: 100px;
    }
}

@media only screen and (max-width:700px) {
 
    .footer_content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 10px;
    }
}

@media only screen and (max-width:600px) {
    .footer_location_img {
        height: auto;
    }

    .footer_content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 50px;
    }
}

@media only screen and (max-width:400px) {
    .container {
        padding: 0px 10px;
    }

    .footer {
        padding: 30px 0px;
    }

    .footer_content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 30px;
    }
}

@media only screen and (max-width:350px) {
    .container {
        padding: 0px 10px;
    }

    .footer {
        padding: 30px 0px;
    }

    .footer_content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px;
    }
}