:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.links {
    margin: 150px 0px 100px 0px;
}

.links_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.links_title {
    font-size: 35px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.links_line {
    width: 100px;
    height: 5px;
    background-color: var(--text-color);
    border-radius: 5px;
}

.links_content {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    grid-template-rows: 230px;
    justify-content: space-between;
    gap: 0px;
}

.links_card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    position: relative;
    cursor: pointer;
}

.links_images_div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    padding-top: 30px;
}

.links_hover_div {
    width: 90%;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    position: absolute;
    background-color: white;
    z-index: 2;
    bottom: -30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--hover-color);
    transition: .5s;
    text-align: center;
    padding: 0px 10px;
}

.links_images {
    width: 100%;
    transition: .5s;
}

.links_card:hover .links_images {
    transform: scale(1.1);
    transition: .5s;
}

.links_card:hover .links_hover_div {
    color: white;
    background-color: var(--hover-color);
    transition: .5s;
}


@media only screen and (max-width:1300px) {
    .links_content {
        display: grid;
        grid-template-columns: 300px 300px;
        grid-template-rows: 230px 230px;
        justify-content: center;
        gap: 100px;
    }
}

@media only screen and (max-width:1024px) {
    .links_title {
        font-size: 24px;
    }
}

@media only screen and (max-width:800px) {
    .links_content {
        grid-template-columns: 270px 270px;
        grid-template-rows: 200px 200px;
        gap: 30px;
        row-gap: 100px;
    }
}

@media only screen and (max-width:600px) {
    .links_content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 180px 180px;
        gap: 30px;
        row-gap: 80px;
    }

    .links_hover_div {
        height: 60px;
        border-radius: 10px;
    }
}

@media only screen and (max-width:600px) {
    .links_content {
        grid-template-columns: 250px;
        grid-template-rows: 200px 200px 200px 200px;
        gap: 30px;
        row-gap: 70px;
    }

    .links_hover_div {
        height: 60px;
        border-radius: 10px;
    }
}