:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.table {
    width: 100%;
    margin: 50px 0px;
}

.thead_th {
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    color: var(--hover-color);
    text-align: start;
}

.data_td {
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    color: var(--text-color);
    text-align: start;
}


@media only screen and (max-width:768px){
    .table {
        margin: 30px 0px;
    }
    
    .thead_th {
        padding: 5px 10px;
        font-size: 14px;
    }
    
    .data_td {
        padding: 5px 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width:600px){
    .thead_th {
        padding: 5px;
        font-size: 12px;
    }
    
    .data_td {
        padding: 5px;
        font-size: 12px;
    }
}

@media only screen and (max-width:480px){
    .thead_th {
        padding: 2px;
    }
    
    .data_td {
        padding: 2px;
    }
}

@media only screen and (max-width:400px){
    .thead_th {
        font-size: 10px;
    }
    
    .data_td {
        font-size: 10px;
    }
}