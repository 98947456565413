:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    position: relative;
    margin-bottom: 20px;
}

.header:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    transition: .5s;
    bottom: 0px;
    border-radius: 1px;
    background: linear-gradient(90deg, rgba(249, 246, 255, 0), #01b3b0 50%, rgba(249, 246, 255, 0));
}

.header_logo {
    width: 150px;
}

.navbar {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.navbar_list {
    position: relative;
    padding: 5px;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    transition: .5s;
    color: var(--text-color);
}


.navbar_list:before {
    content: "";
    width: 0%;
    height: 2px;
    position: absolute;
    transition: .5s;
    bottom: 0px;
    border-radius: 1px;
    background: radial-gradient(55.02% 674.98% at 48.31% 50%, #01b3b0 0, rgba(245, 134, 51, 0) 97.32%);
    box-shadow: 4px 4px 36.099998474121094px 0 var(--hover-color)
}

.navbar_list:hover:before {
    width: 100%;
    transition: .5s;
}

.language_content {
    position: relative;

}

.language_value {
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.language_all {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    gap: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    position: absolute;
    left: 0px;
    top: 25px;
    padding: 5px 0px;
}

.language_btn:first-child {
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0px 10px 0px 10px;
    text-transform: uppercase;
}

.language_btn:nth-child(2) {
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0px 10px 0px 10px;
    text-transform: uppercase;

}

.language_btn:hover {
    background-color: rgb(230, 230, 230);
}

.header_menu_active {
    font-size: 30px;
    color: var(--text-color);
    cursor: pointer;
    display: none;
    transition: .5s;
}

.header_menu_active:hover {
    color: var(--hover-color);
    transition: .5s;
}


@media only screen and (max-width:1024px) {
    .header_logo {
        width: 120px;
    }

    .language_all {
        left: 0px;
    }

    .language_btn:first-child,
    .language_btn:nth-child(2) {
        padding: 0px 10px 0px 10px;
    }

    .header {
        gap: 30px;
        height: 80px;
    }

    .navbar {
        width: 100%;
    }

}

@media only screen and (max-width:900px) {
    .header_logo {
        width: 110px;
    }

    .header {
        gap: 20px;

    }
}


@media only screen and (max-width:850px) {
    .header {
        height: 60px;
        gap: 10px;
    }

    .navbar {
        gap: 10px;

    }

    .navbar_list {
        padding: 5px 0px 5px 0px;
        font-size: 16px;
    }

    .language_value {
        font-size: 16px;
    }

    .header_menu_active {
        display: none;
    }

}

@media only screen and (max-width:768px) {
    .header_logo {
        width: 85px;
    }

    .navbar {
        display: none;
    }

    .header_menu_active {
        display: block;
    }
}

@media only screen and (max-width:400px) {
    .container {
        padding: 0px 10px;
    }

    .header {
        height: 50px;
    }
}