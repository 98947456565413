:root {
    --text-color: #01b3b0;
    --hover-color: #008080;
}

.main {
    background-image: url("../../assets/images/main.avif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 0px;
    margin-bottom: 50px;
}

.main_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main_text_content {
    display: flex;
    flex-direction: column;
}

.main_title_text {
    font-size: 36px;
    color: var(--text-color);
}

.main_title {
    font-size: 67px;
    color: var(--text-color);

    margin: 10px 0px 20px 0px;
}

.main_text {
    max-width: 462px;
    font-size: 24px;
    color: var(--text-color);

}

.swiper {
    width: 50%;
}

.main_images {
    width: 100%;
    border-radius: 50px;
}

.autoplay-progress {
    display: none;
}

.main_media_line {
    width: 100%;
    height: 5px;
    background-color: var(--text-color);
    border-radius: 10px;
    margin-top: 10px;
    display: none;
}


@media only screen and (max-width:1200px) {

    .main_content {
        gap: 50px;
    }


    .main_title_text {
        font-size: 26px;
    }

    .main_title {
        font-size: 45px;
        margin: 10px 0px;
    }

    .main_text {
        width: 100%;
        font-size: 22px;
    }

    .main_images {
        border-radius: 20px;
    }

}


@media only screen and (max-width:1024px) {
    .main {
        padding: 25px 0px;
    }

    .main_content {
        gap: 20px;
    }

    .main_title {
        font-size: 42px;
    }

    .main_text {
        font-size: 20px;
    }

}

@media only screen and (max-width:950px) {
    .main {
        padding: 20px 0px;
    }

    .main_content {
        gap: 10px;
    }

    .main_title_text {
        font-size: 22px;
    }

    .main_title {
        font-size: 36px;
        margin: 15px 0px;
        line-height: 40px;
    }

    .main_text {
        font-size: 18px;
        line-height: 22px;
    }

}

@media only screen and (max-width:768px) {

    .main_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
    }

    .swiper {
        width: 70%;
    }


    .main_title {
        margin: 15px 0px;
    }

    .main_text_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}


@media only screen and (max-width:500px) {

    .swiper {
        width: 80%;
    }

    .main_media_line {
        display: block;
    }

    .main_title_text {
        font-size: 18px;
    }

    .main_title {
        font-size: 28px;
        margin: 10px;
        line-height: 30px;
    }

    .main_text {
        font-size: 16px;
        line-height: 18px;
    }
}